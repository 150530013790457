import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';


class PageTemplate extends React.Component {
  render() {
    const item = this.props.data.contentfulPage;
    const { permalink } = this.props.pageContext;
    const { title, image, body, sideBarText } = item;

    const imageSrc = image && image.file ? `${image.file.url}?w=600` : null;
    const bodyHtml = body.childMarkdownRemark ? body.childMarkdownRemark.html : null;
    const sidebarTextHtml = sideBarText && sideBarText.childMarkdownRemark ? sideBarText.childMarkdownRemark.html : null;
    const sectionStyle = {};

    let btn = null;
    const btnClasses = "text-white hover:bg-teal-600 bg-teal-500 border-0 py-2 px-8 focus:outline-none rounded text-lg";
    if (permalink === '/history' || permalink === '/') {
      btn = <Link className={btnClasses} to="/donations">Donate To MLS</Link>

    }

    let textColor = "text-gray-700";
    let showTitle = true;
    if (permalink === '/') {
      showTitle = false;
      sectionStyle.backgroundImage = "url('https://images.ctfassets.net/ojc3tre6o901/3XmTWJRGkMTV4ahMhdVQO5/61267b2695ea544e717b98bb76dadbfc/Valley-10s-1360px.svg')";
      sectionStyle.backgroundSize = "cover";
      sectionStyle.backgroundPosition = "0px 0px";
      sectionStyle.backgroundRepeat = "no-repeat";
      textColor = "text-gray-900";
    }
    return (
      <Layout>
        <SEO title={title} type="article" ogImage={imageSrc} permalink={`${process.env.GATSBY_APP_URL}${permalink}`} />
        <section className={`${textColor} body-font`} style={sectionStyle}>
          <div className="container px-5 py-12 mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 mb-20">
              <div className="text-left w-full px-4">
                {showTitle && <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{title}</h1>}
                <div className="leading-relaxed text-base has-markdown-inside" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                {btn}
              </div>
              <div className="w-full text-left px-4 my-4 md:my-0">
                {sidebarTextHtml && <div className="leading-relaxed text-base has-markdown-inside" dangerouslySetInnerHTML={{ __html: sidebarTextHtml }} />}
                {imageSrc && <img alt="content" className="object-cover object-center w-full" src={imageSrc} />}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($permalink: String!) {
    contentfulPage(permalink: { eq: $permalink }) {
      body {
        childMarkdownRemark {
          html
        }
      }
      sideBarText {
        childMarkdownRemark {
          html
        }
      }
      id
      permalink
      title
      image {
        file {
          url
        }
      }
    }
  }
`;